import React from 'react'
import './../style/home.css'
import { Navbar } from '../layout/navbar'
export const Homepage = () => {
  return (
    <div className='container'>
        <div className='topgap'>&nbsp;</div>
        <div className='mainContent'>
        <Navbar /> 
            <div className='leftText'>
              <h1 className='mainText'>To inspire and grow<br/>the love of music, <br/>by providing musical instruments<br/>and lessons to the underserved<br/>youth in our area.</h1>
            </div>
            <div className='rightText'>
              <img src='./sax.jpg' className='heroimages' />
            </div>
            </div>
    </div>
  )
}
