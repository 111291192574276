import React from 'react';
import { Outlet, Link } from "react-router-dom";
import '../style/home.css';
import './../App.css';
import { useState } from 'react';

export const Navbar = () => {
  const [navHeight, setNavHeight] = useState("0%");

  const openNav = () => {
    setNavHeight("100%");
  };

  const closeNav = () => {
    setNavHeight("0%");
  };

  return (
    <header style={{ color: "#ffffff"}}>
              <div className='leftLinks'>
                <div className='menulinks1'><Link className='links' to="/"><img className='logoimgs' src='727.png' /></Link></div>
                <div className='menulinks topmargin'><Link className='links' to="./">Events</Link></div>
                <div className='menulinks topmargin'><Link className='links' to="./">About Us</Link></div>
                <div className='menulinks topmargin'><Link className='links' to="./">Gear</Link></div>
                <div className='Contact topmargin'><Link className='links' to="/ContactUs">Contact Us</Link></div>
                <div className='Donate topmargin' style={{marginTop: "115px;"}}><Link className='linkingDonate' to="/donate">Donate</Link></div>
                <span className='hamburger' style={{fontSize:"38px",pointerEvents:"pointet"}} onClick={openNav}>&#9776;</span>
              </div>

              
            
              <div className='leftLinks'>
              <div className='Social topmargin'></div>
              </div>

              <div id="myNav" className="overlay" style={{ height: navHeight }}>
              <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>&times;</a>
              <div class="overlay-content">
                <a><Link className='links' to="./">Events</Link></a>
                <a><Link className='links' to="./">About Us</Link></a>
                <a><Link className='links' to="./">Gear</Link></a>
                <a><Link className='links' to="/ContactUs">Contact Us</Link></a>
                <a style={{justifyContent: "center", display:"grid"}}><Link style={{width:"150px"}} className='linkingDonate' to="/donate">Donate</Link></a>
              </div>
            </div>



            </header>
  )
}